<template>
    <div>
        <h6 class="gutter-t">{{ $t('NOTIFICATIONS.TEMPLATES.TITLE') }}</h6>
        <div class="card gutter-t">
            <div class="card-header border-bottom-0">
                <div class="w-25">
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        :placeholder="$t('NOTIFICATIONS.TEMPLATES.TABLE.SEARCH_TEMPLATE')"
                        debounce="300"
                    ></b-form-input>
                </div>
            </div>
            <div class="card-body">
                <st-data-table
                    :items="templates"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :per-page="perPage"
                    responsive="sm"
                    hover
                    @edit="editTemplate"
                    :actions="actions"
                    :loading="loadingNotificationsTemplates"
                >
                </st-data-table>
            </div>
            <div class="card-footer border-top-0">
                <div class="d-flex justify-content-end">
                    <b-pagination
                        v-model="page"
                        :total-rows="rows"
                        :per-page="perPage"
                        class="mr-10"
                        :prev-text="$t('GENERAL.PREV')"
                        :next-text="$t('GENERAL.NEXT')"
                        first-number
                        last-number
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { NotificationsPermissions } from '../notifications-permissions';
import { BPagination } from 'bootstrap-vue';

export default {
    components: {
         'b-pagination': BPagination,
    },
    data() {
        return {
            editMode: false,
            sortBy: 'name',
            sortDesc: false,
            fields: [
                {
                    key: 'id',
                    label: this.$t('NOTIFICATIONS.TEMPLATES.TABLE.ID'),
                },
                {
                    key: 'title',
                    label: this.$t('NOTIFICATIONS.TEMPLATES.TABLE.TITLE'),
                    sortable: true,
                },
                {
                    key: 'channel',
                    label: this.$t('NOTIFICATIONS.TEMPLATES.TABLE.TYPE'),
                },
                {
                    key: 'language',
                    label: this.$t('NOTIFICATIONS.TEMPLATES.TABLE.LANGUAGE'),
                },
                'Actions',
            ],
            actions: [
                {
                    name: 'edit',
                    icon: 'edit',
                    tooltipText: this.$t('NOTIFICATIONS.TEMPLATES.TABLE.EDIT'),
                    customIcon: false,
                    type: 'primary',
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            templates: 'notifications/templates/templates',
            rows: 'notifications/templates/rows',
            perPage: 'notifications/templates/perPage',
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        ...mapState({
            search: (state) => state.notifications.templates.search,
            currentPage: (state) => state.notifications.templates.currentPage,
        }),
        filter: {
            set(val) {
                this.updateSearch(val);
                this.page = 1;
                this.retrieveData();
            },
            get() {
                return this.search;
            },
        },
        page: {
            set(val) {
                this.updateCurrentPage(val);
                this.retrieveData();
            },
            get() {
                return this.currentPage;
            },
        },
        loadingNotificationsTemplates() {
            return this.loading['notifications/templates/getNotificationsTemplates'];
        },
        hasPermissionToEdit() {
            return new NotificationsPermissions(this.currentUser).edit;
        }
    },
    created() {
        this.retrieveData();
    },
    methods: {
        ...mapActions({
            getNotificationsTemplates: 'notifications/templates/getNotificationsTemplates',
            updateSearch: 'notifications/templates/updateSearch',
            updateCurrentPage: 'notifications/templates/updateCurrentPage',
        }),
        edit(id) {
            this.$router.push({
                name: 'notifications-templates-edit',
                params: {
                    id,
                },
            });
        },
        editTemplate(data) {
            this.edit(data.item.id);
        },
        retrieveData() {
            this.getNotificationsTemplates({
                skip: (this.page - 1) * this.perPage,
                limit: this.perPage,
                query: this.filter ? `title like '%${this.filter}%'` : '',
            });
        },
    }
}
</script>
